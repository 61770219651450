import React from "react";
import { RichText } from "prismic-reactjs";
import "./style.scss";

export default function features({ data }) {
  return (
    <section className="services-decSecOps-features">
      <div className="container">
        <div className="services-decSecOps-features-content">
          <RichText render={data.primary.feature_one} />
          <RichText render={data.primary.feature_two} />
        </div>
      </div>
    </section>
  );
}

import React from "react";
import Layout from "../../components/layout";
import SecondaryFooter from "../../components/layout/secondaryFooter";
import Feature from "../../components/services/devSecOps/features";
import Hero from "../../components/services/hero";
import { graphql } from "gatsby";

export default function AppSecurity(props) {
  if (!props.data) return null;
  const data = props.data.allPrismicServicesDevsecops.edges[0].node.dataRaw;
  return (
    <Layout>
      <Hero data={data} />
      <Feature data={data.body[0]} />
      <SecondaryFooter />
    </Layout>
  );
}

export const devsecops = graphql`
  query devsecops {
    allPrismicServicesDevsecops {
      edges {
        node {
          dataRaw
        }
      }
    }
  }
`;
